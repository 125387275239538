import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import BookingFragment from "../../fragments/booking/booking.fragment";

export default addAuthVars(gql`
  ${BookingFragment}
  query fetchMemberBookings(
    $memberId: ID!
    $stateNotIn: [BookingStateType]
    $stateIn: [BookingStateType]
    $stateEquals: BookingStateType
    $limit: Int!
    $offset: Int!
    $startBefore: Date
    $startAfter: Date
    $isWithAccount: Boolean
    $orderByColumn: String
    $orderByDirection: String
    $selectedAccountId: ID
  ) {
    account {
      id
      member(id: $memberId) {
        id
        bookingsCount {
          appliedCount
          upcomingAccepted
          previouslyAccepted
          waitingListCount
          cancelledCount
          noShowCount
        }
        bookings(
          offset: $offset
          limit: $limit
          stateNotIn: $stateNotIn
          stateIn: $stateIn
          stateEquals: $stateEquals
          startBefore: $startBefore
          startAfter: $startAfter
          isWithAccount: $isWithAccount
          orderByColumn: $orderByColumn
          orderByDirection: $orderByDirection
        ) {
          data {
            ...BookingFragment
            timesheetMemberRow {
              id
              timesheet {
                status
              }
            }
            shift {
              id
              startTime
              endTime
              venue {
                id
                name
              }
              sourceAccount {
                id
                accountName
              }
              role {
                id
                name
              }
            }
          }
          offset
          limit
        }
      }
    }
  }
`);
