import React, { useReducer } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { TIMESHEET_STATUS, ZonedDate } from "@teamrota/rota-common";
import { RotaButton, RotaSnackBar } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";
import { errorModal } from "~/src/utils/errors";

import TimesheetDetailsComponent from "./timesheet-details";
import { getValuesFromProps } from "./timesheet-details/schema";
import { UPDATE_TIMESHEET_MEMBER } from "./graphql/update-timesheet-member";
import { validationSchema } from "./timesheet-details/validate";
import { reducer } from "./reducer";
import { Form, Row, Section } from "./styles";

const initialState = {
  isInEditMode: false,
  isSnackOpen: false,
  isSaving: false,
  hasSaved: false,
  errorMessage: ""
};

const changeDateTimeToTimeString = date =>
  moment(date)
    .format("HH:mm")
    .toString();

const changeDateTimeToDateString = date =>
  moment(date)
    .format("YYYY-MM-DD")
    .toString();

const getDates = data => {
  return {
    scheduledStartTime: new ZonedDate(`${data.startDate} ${data.startTime}`),
    scheduledEndTime: new ZonedDate(`${data.endDate} ${data.endTime}`)
  };
};

const ViewEditTimesheetFormComponent = props => {
  const [
    { isInEditMode, isSnackOpen, isSaving, hasSaved, errorMessage },
    dispatch
  ] = useReducer(reducer, initialState);

  const [updateTimesheetMember] = useMutation(UPDATE_TIMESHEET_MEMBER, {
    refetchQueries: ["getTimesheetMember"]
  });

  const auth = useAuth();
  const isUserCanEdit = auth.hasRole(Role.SHIFTS_EDIT);
  const valuesFromApi = getValuesFromProps(props);
  const startDate = changeDateTimeToDateString(valuesFromApi?.startTime);
  const endDate = changeDateTimeToDateString(valuesFromApi?.endTime);
  const startTime = changeDateTimeToTimeString(valuesFromApi?.startTime);
  const endTime = changeDateTimeToTimeString(valuesFromApi?.endTime);
  const initialValues = {
    ...valuesFromApi,
    startDate,
    startTime,
    endTime,
    endDate
  };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  const makeUpdates = async () => {
    const data = getValues();
    const dates = getDates(data);
    const updatedData = { ...data, ...dates };
    try {
      dispatch({ type: "SUBMIT_START" });
      await updateTimesheetMember({
        variables: { ...updatedData, id: data.timesheetMemberRowId }
      });
      setValue("approveMember", undefined);
      setValue("feedbackForMember", undefined);

      dispatch({ type: "SUBMIT_END" });
    } catch (e) {
      errorModal(e);
      dispatch({
        type: "SUBMIT_ERROR",
        errorMessage:
          e?.message || "An error occured, please check your changes."
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(makeUpdates)}>
        <Section>
          <TimesheetDetailsComponent
            {...props}
            register={register}
            errors={errors}
            initialValues={initialValues}
            control={control}
            isInEditMode={isInEditMode && isUserCanEdit}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </Section>
        <Row style={{ margin: "20px 0", justifyContent: "end" }}>
          {!isInEditMode && isUserCanEdit && (
            <HasRole role={Role.SHIFTS_EDIT}>
              <RotaButton
                onClick={() => dispatch({ type: "SET_EDIT_MODE_ON" })}
                variant="outlined"
                size="small"
                text="Edit"
                colors="grey"
                style={{ marginBottom: 10 }}
                disabled={props.timesheetStatus === TIMESHEET_STATUS.LOCKED}
              >
                Edit
              </RotaButton>
            </HasRole>
          )}
          {isInEditMode && (
            <RotaButton
              onClick={() => {
                reset();
                dispatch({ type: "SET_EDIT_MODE_OFF" });
              }}
              variant="outlined"
              size="small"
              colors="grey"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </RotaButton>
          )}
          {isInEditMode && isUserCanEdit && (
            <RotaButton size="small" disabled={isSaving} type="submit">
              {hasSaved ? "Saved" : "Save"}
            </RotaButton>
          )}
        </Row>
      </Form>

      <RotaSnackBar
        snackOpen={isSnackOpen}
        onClose={() => {
          dispatch({ type: "CLOSE_SNACK" });
        }}
        message={errorMessage || "Success"}
        severity={errorMessage ? "error" : "success"}
      />
    </>
  );
};

export default ViewEditTimesheetFormComponent;
